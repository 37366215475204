import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import ArrowHubIcon from 'components/icons/arrow-hub';
import { useFormat } from 'helpers/hooks/useFormat';
import type { Reference } from 'types/reference';
import { useAccount } from 'frontastic';
import Integrity from './sections/integrity';
import PersonalInfo from './sections/personal-info';

interface Props {
    isLoading?: boolean;
    staticText?: string;
    learningHubTitle?: string;
    learningHubCopy?: string;
    digitalHubLink?: Reference;
}

const MyAccount: React.FC<Props> = ({ isLoading, staticText, learningHubTitle, learningHubCopy, digitalHubLink }) => {
    const { account } = useAccount();

    const { formatMessage } = useFormat({ name: 'account' });

    return (
        <div className="mt-15 px-16 md:px-24 lg:mt-42 lg:px-44">
            {/* Title and subtitle */}
            <div className="mb-32 grid gap-20 md:mb-33 lg:gap-24">
                {isLoading ? (
                    <Skeleton className="h-30 max-w-300" />
                ) : (
                    <Typography as="h3" className="hidden text-20 text-primary-black md:block lg:text-24">
                        {`${formatMessage({ id: 'hello', defaultMessage: 'Hi, ' })}${account?.firstName ?? ''}`}
                    </Typography>
                )}

                {learningHubTitle && learningHubCopy && (
                    <>
                        <Link
                            link={digitalHubLink}
                            target="_blank"
                            className="relative mt-10 flex flex-col rounded-tile bg-hub-bg p-20 text-hub-text hover:opacity-80 md:mt-0 md:flex md:flex-row md:items-center md:justify-between md:px-30 md:py-25"
                        >
                            <div className="md:max-w-[calc(100%-70px)]">
                                <Typography className="mb-8 font-heading text-20 leading-tight text-hub-text md:text-25">
                                    {learningHubTitle}
                                </Typography>
                                <Typography className="text-16 text-hub-text md:text-20">{learningHubCopy}</Typography>
                            </div>
                            <div className="relative mt-5 size-44 self-end  rounded-full bg-hub-text md:mt-0 md:self-center xl:size-54">
                                <ArrowHubIcon className="absolute left-1/2 top-1/2 w-25  -translate-x-1/2 -translate-y-1/2 lg:w-31" />
                            </div>
                        </Link>
                    </>
                )}
                {isLoading && staticText ? (
                    <Skeleton className="max-w-400" />
                ) : (
                    <Typography className="text-14 text-secondary-black md:text-16">{staticText}</Typography>
                )}
            </div>

            {/* Sections */}
            <div className="grid gap-30 md:gap-24 lg:gap-60">
                {isLoading ? (
                    <Skeleton className="h-350" />
                ) : (
                    <>
                        <PersonalInfo account={account!} />

                        <Integrity />
                    </>
                )}
            </div>
        </div>
    );
};

export default MyAccount;
