import { useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { useAccount, useCart } from 'frontastic/hooks';

const useUpdateCartEmail = () => {
    const router = useRouter();

    const { account, accountLoading } = useAccount();
    const { updateCart, data, isLoading: isCartLoading } = useCart();

    const updateCartEmail = useCallback(async () => {
        if (accountLoading || isCartLoading) {
            return;
        }

        if (!account?.email) {
            router.replace('/login');
        } else if (!data?.email) {
            const email = account.email;

            await updateCart({
                account: { email }
            });
        }
    }, [accountLoading, isCartLoading, account?.email, data?.email, updateCart, router]);

    return updateCartEmail;
};

export default useUpdateCartEmail;
