import type { FC } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import Icon from 'components/icons/edit';
import { useFormat } from 'helpers/hooks/useFormat';

type EditCTAProps = {
    editHref: string;
};

const EditCTA: FC<EditCTAProps> = ({ editHref }) => {
    const { formatMessage } = useFormat({ name: 'common' });
    return (
        <Link link={editHref} className="h-fit">
            <Typography className="hidden text-md text-primary-black underline hover:cursor-pointer md:block">
                {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
            </Typography>
            <div className="relative block md:hidden">
                <Icon className="size-20 text-secondary-black" />
            </div>
        </Link>
    );
};

export default EditCTA;
