import type { FC } from 'react';
import React from 'react';
import OrderSummary from 'components/commercetools-ui/organisms/order-summary';
import OrderActions from './order-actions';
import OrderInfoSection from './order-info';
import OrderNumber from './order-number';
import useOrderFetch from '../helper-hooks/useOrderFetch';

export interface Props {
    orderId?: string;
}

const OrderPage: FC<Props> = ({ orderId }) => {
    const { orders, loading } = useOrderFetch();

    const order = orders.find(order => order.orderId === orderId);

    return (
        <div className="md:mt-20 lg:mt-40">
            <OrderNumber orderNumber={order?.orderId ?? ''} asSkeleton={loading} />

            <div className="mt-30 flex px-16 md:px-24 lg:flex-col lg:px-30 xl:flex-row">
                <OrderInfoSection order={order} orderState={order?.orderState ?? 'Registered'} asSkeleton={loading} />

                <OrderSummary
                    order={order}
                    includeItemsList
                    includeSummaryAccordion
                    className="ml-30 hidden h-fit w-[42%] rounded-md border lg:px-36 lg:py-20 2xl:block 3xl:w-[37%]"
                    classNames={{
                        itemsList: 'border-transparent lg:m-0',
                        subCostsContainer: 'border-b border-neutral-400 pb-24',
                        totalAmount: 'mt-16',
                        infoContainer: 'lg:pt-24'
                    }}
                    button={undefined}
                    dataReference="order"
                    asSkeleton={loading}
                />
            </div>

            <div className="mx-16 mt-26 flex flex-col gap-20 md:mx-24 lg:mx-30 lg:flex-row lg:gap-15">
                <OrderActions />
            </div>
        </div>
    );
};

export default OrderPage;
