import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import type { InfoField } from '../../../account-atoms/info-card';
import InfoCard from '../../../account-atoms/info-card';

const Integrity = () => {
    const { formatMessage } = useFormat({ name: 'account' });

    // Integrity fields
    const integrityButtons: InfoField[] = [
        {
            label: formatMessage({ id: 'password.change', defaultMessage: 'Change your password' }),
            value: '?id=change-password'
        }
    ];

    return (
        <InfoCard title={formatMessage({ id: 'security', defaultMessage: 'Security' })} clearPadding cardClassName="grid">
            {integrityButtons.map(({ label, value }, index) => (
                <Link key={label} link={value} className="hover:cursor-pointer">
                    <div className="flex items-center justify-between pr-32 md:pr-36">
                        <Typography fontSize={14} className="py-24 pl-16 pr-32 font-bold text-primary-black md:pl-24">
                            {label}
                        </Typography>

                        <ChevronRightIcon className="h-24 w-20 text-secondary-black" />
                    </div>

                    {index === 0 && <hr />}
                </Link>
            ))}
        </InfoCard>
    );
};

export default Integrity;
