import type { FC } from 'react';
import React from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';

const OrderActions: FC = () => {
    const { customerSupportLink } = useBrandSettingsContext();
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    return (
        <>
            <Button variant="secondary" size="s" className="rounded-md px-16 py-8">
                <Link link={'/account/?hash=orders'}>
                    <Typography className="text-center">{formatOrdersMessage({ id: 'button.back', defaultMessage: 'Back' })}</Typography>
                </Link>
            </Button>

            {customerSupportLink && (
                <Button variant="primary" size="s" className="mb-20 rounded-md px-16 py-8 lg:mb-0">
                    <Link link={customerSupportLink}>
                        <Typography className="text-center">
                            {formatOrdersMessage({ id: 'button.contact.support', defaultMessage: 'Contact Customer Support' })}
                        </Typography>
                    </Link>
                </Button>
            )}
        </>
    );
};

export default OrderActions;
