import type { FC } from 'react';
import React from 'react';
import { useParams } from 'next/navigation';
import type { Order } from '@wilm/shared-types/cart/Order';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import mapCosts from 'helpers/utils/mapCosts';

interface Props {
    order?: Order;
    loading?: boolean;
}

const OrderItem: FC<Props> = ({ order, loading }) => {
    const { currency } = useI18n();
    const { locale } = useParams();
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const { total: getTotal } = mapCosts({ order, currency });
    const orderDate = order?.createdAt && new Date(order?.createdAt).toISOString().split('T')[0].split('-');
    const formatedDate = orderDate && [orderDate[2], orderDate[1], orderDate[0]].join('/');

    return (
        <div className="mb-15 w-full border border-input-disabled">
            <div className="grid grid-cols-1 items-center p-15 text-md xl:grid-cols-5 xl:p-30">
                {!loading ? (
                    order?.orderId && (
                        <div className="flex xl:items-center">
                            <Typography className="whitespace-nowrap font-bold xl:whitespace-normal">
                                {formatOrdersMessage({ id: 'order.number', defaultMessage: 'Order number:' })}
                            </Typography>
                            <Typography className="pl-5" data-cy="order-id">
                                {order?.orderId?.replaceAll('-', ' ')}
                            </Typography>
                        </div>
                    )
                ) : (
                    <Skeleton />
                )}
                {!loading ? (
                    formatedDate && (
                        <div className="flex xl:items-center xl:justify-center">
                            <Typography className="font-bold">
                                {formatOrdersMessage({ id: 'order.created.date', defaultMessage: 'Created date:' })}
                            </Typography>
                            <Typography className="pl-5">{formatedDate}</Typography>
                        </div>
                    )
                ) : (
                    <Skeleton />
                )}
                {!loading ? (
                    getTotal && (
                        <div className="flex xl:justify-center">
                            <Typography className="font-bold">
                                {formatOrdersMessage({ id: 'order.total', defaultMessage: 'Total:' })}
                            </Typography>
                            <Typography className="pl-5">{CurrencyHelpers.formatForCurrency(getTotal, locale)}</Typography>
                        </div>
                    )
                ) : (
                    <Skeleton />
                )}
                {!loading ? (
                    order?.orderState && (
                        <div className="flex xl:justify-center">
                            <Typography className="font-bold">
                                {formatOrdersMessage({ id: 'order.status', defaultMessage: 'Status:' })}
                            </Typography>
                            <Typography className="pl-5">{order?.orderState}</Typography>
                        </div>
                    )
                ) : (
                    <Skeleton />
                )}
                <div className="text-end">
                    {!loading ? (
                        <Button variant="primary" size="s" className="mt-20 rounded-md px-16 py-8 xl:mt-0">
                            <Link link={`/account/?hash=orders&id=order_${order?.orderId}`} data-cy="order-details">
                                <Typography>{formatOrdersMessage({ id: 'view.details', defaultMessage: 'View details' })}</Typography>
                            </Link>
                        </Button>
                    ) : (
                        <Skeleton className="h-24 w-100" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderItem;
