import React from 'react';
import type { Order } from '@wilm/shared-types/cart/Order';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useOrderFetch from './helper-hooks/useOrderFetch';
import OrderItem from './OrderItem';

const Orders = () => {
    const { orders, loading } = useOrderFetch();
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    return (
        <>
            <Typography as="h2" className="ml-15 mt-22 text-18 text-primary-black md:ml-24 md:text-22 lg:ml-44 lg:mt-42">
                {formatOrdersMessage({
                    id: 'orders',
                    defaultMessage: 'Orders'
                })}
            </Typography>

            <div className="mb-5 mt-15 px-16 md:mt-20 md:px-24 lg:mb-18 lg:px-44">
                <Typography as="h3" className="text-md text-secondary-black md:text-16">
                    {formatOrdersMessage({
                        id: 'help.question',
                        defaultMessage: 'Check status of recent orders, manage your returns and download invoices.'
                    })}
                </Typography>
            </div>
            <div className="overflow-auto px-15 pt-15 md:px-24 lg:px-44">
                {loading ? (
                    <OrderItem loading={loading} />
                ) : (
                    orders?.map((order?: Order) => <OrderItem key={order?.orderId} order={order} />)
                )}
            </div>
        </>
    );
};

export default Orders;
