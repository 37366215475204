'use client';

import React, { useEffect } from 'react';
import type { AccountDetailsProps } from 'components/commercetools-ui/organisms/account';
import AccountDetails from 'components/commercetools-ui/organisms/account';
import useUpdateCartEmail from 'helpers/hooks/useUpdateCartEmail';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic/hooks';
import type { TasticProps } from 'frontastic/tastics/types';

const AccountDetailsTastic = ({ data }: TasticProps<AccountDetailsProps>) => {
    const { loggedIn } = useAccount();

    const updateCartEmail = useUpdateCartEmail();

    useEffect(() => {
        void updateCartEmail();
    }, [updateCartEmail]);

    return !loggedIn ? <Redirect target={'/login'} /> : <AccountDetails {...data} />;
};

export default AccountDetailsTastic;
