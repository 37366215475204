import type { FC } from 'react';
import React from 'react';
import { useParams } from 'next/navigation';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { calculateAndFormatMembershipStartDate, formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import type { Reference } from 'types/reference';
import useSubscriptionsFetch from '../helper-hooks/useSubscriptionsFetch';

interface Props {
    orderId?: string;
    digitalHubLink?: Reference;
    customerSupportLink?: Reference;
}

interface Member {
    firstName?: string;
    lastName?: string;
    email?: string;
}

const OrderItem: FC<Props> = ({ orderId, digitalHubLink, customerSupportLink }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const { locale } = useParams();

    const { ordersSubscription, loading } = useSubscriptionsFetch();
    const order = ordersSubscription.find(order => order.orderId === orderId)!;
    const orderItems = order?.lineItems?.[0];
    const orderAttributes = orderItems?.variant?.attributes;

    const endDate = order?.custom?.fields?.nextRenewDate;
    const formatedEndDate = endDate && formatDate(endDate);
    const duration = Number(order?.custom?.fields?.duration);
    const formatedStartDate = endDate && duration && calculateAndFormatMembershipStartDate(endDate, duration);

    const productAttributes = [
        {
            label: formatOrdersMessage({ id: 'start.date', defaultMessage: 'Start date:' }),
            value: formatedStartDate
        },
        {
            label: formatOrdersMessage({ id: 'end.date', defaultMessage: 'End date:' }),
            value: formatedEndDate
        },
        {
            label: formatOrdersMessage({ id: 'price', defaultMessage: 'Price:' }),
            value: order?.sum && CurrencyHelpers.formatForCurrency(order.sum, locale)
        },
        {
            label: formatOrdersMessage({ id: 'duration', defaultMessage: 'Duration:' }),
            value:
                orderAttributes?.duration &&
                `${orderAttributes?.duration} ${formatOrdersMessage({ id: 'subscription.duration.months', defaultMessage: 'months' })}`
        },
        {
            label: formatOrdersMessage({ id: 'type', defaultMessage: 'Type:' }),
            value: orderAttributes?.level
        },
        {
            label: formatOrdersMessage({ id: 'status', defaultMessage: 'Status:' }),
            value: order?.state
        }
    ];

    return (
        <div className="px-15 md:px-24 lg:px-44">
            {!loading ? (
                <Typography as="h2" className="mt-22 text-18 text-primary-black  md:text-22 lg:mt-42">
                    {orderItems?.name}
                </Typography>
            ) : (
                <Skeleton className="h-24" />
            )}

            <div className="mb-5 mt-15 flex text-md md:mt-20 lg:mb-18 lg:text-base">
                <Typography className="whitespace-nowrap font-bold" asSkeleton={loading}>
                    {formatOrdersMessage({ id: 'order.number', defaultMessage: 'Order number:' })}
                </Typography>
                <Typography className="pl-5 text-md text-secondary-black md:text-16" asSkeleton={loading}>
                    {orderId}
                </Typography>
            </div>

            <div className="my-15 w-full max-w-[855px] border border-input-disabled p-15 text-md lg:mb-72 lg:mt-33 lg:p-30">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="leading-loose">
                        {!loading ? (
                            productAttributes.map(
                                productAttribute =>
                                    productAttribute.value && (
                                        <div className="flex" key={productAttribute.value}>
                                            <Typography className="font-bold">{productAttribute.label}</Typography>
                                            <Typography className="pl-5">{productAttribute.value}</Typography>
                                        </div>
                                    )
                            )
                        ) : (
                            <Skeleton className="h-125" />
                        )}

                        {!loading ? (
                            <div className="flex">
                                <Typography className="font-bold">
                                    {formatOrdersMessage({ id: 'subscription.qty', defaultMessage: 'Quantity: ' })}
                                </Typography>
                                <Typography className="pl-5">{orderItems?.count?.toString()}</Typography>
                            </div>
                        ) : (
                            <Skeleton className="h-20" />
                        )}
                    </div>
                    {orderAttributes?.members && (
                        <div className="mt-20 lg:mt-0">
                            <Typography className="font-bold">
                                {formatOrdersMessage({ id: 'members', defaultMessage: 'Member(s):' })}
                            </Typography>
                            {orderAttributes?.members.map((member: Member) => (
                                <div key={member.email} className="leading-5">
                                    `${member.firstName} ${member.lastName} - ${member.email}`
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {!loading ? (
                    orderAttributes?.shortDescription && (
                        <Typography className="mt-20 font-bold">{orderAttributes?.shortDescription}</Typography>
                    )
                ) : (
                    <Skeleton className="mt-16" />
                )}
                {!loading ? (
                    orderAttributes?.longDescription && <Typography className="mt-15">{orderAttributes?.longDescription}</Typography>
                ) : (
                    <Skeleton className="mt-16" />
                )}
            </div>
            <div className="flex flex-col gap-20 lg:flex-row lg:gap-15">
                <Button variant="secondary" size="s" className="rounded-md px-16 py-8">
                    <Link link={`/account/?hash=subscriptions`}>
                        <Typography className="text-center">
                            {formatOrdersMessage({ id: 'button.back', defaultMessage: 'Back' })}
                        </Typography>
                    </Link>
                </Button>

                <Button variant="primary" size="s" className="rounded-md px-16 py-8">
                    <Link link={digitalHubLink}>
                        <Typography className="text-center">
                            {formatOrdersMessage({ id: 'button.digital.hub', defaultMessage: 'Go to Digital Hub' })}
                        </Typography>
                    </Link>
                </Button>

                <Button variant="primary" size="s" className="mb-20 rounded-md px-16 py-8 lg:mb-0">
                    <Link link={customerSupportLink}>
                        <Typography className="text-center">
                            {formatOrdersMessage({ id: 'button.contact.support', defaultMessage: 'Contact Customer Support' })}
                        </Typography>
                    </Link>
                </Button>
            </div>
        </div>
    );
};

export default OrderItem;
