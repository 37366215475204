import React from 'react';
import { useRouter } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import PaymentCard from './payment-card';

const PaymentMethods = () => {
    const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
    const router = useRouter();
    const { account } = useAccount();

    return (
        <div className="px-16 md:mt-24 md:px-24 lg:mt-40 lg:px-44">
            <div className="hidden md:block">
                <Typography as="h2" className="text-18 text-primary-black md:text-22 lg:text-24">
                    {formatPaymentMessage({
                        id: 'payment.methods',
                        defaultMessage: 'Payment methods'
                    })}
                </Typography>
            </div>
            <div className="mt-20 lg:mt-36">
                <Typography as="h2" className="text-14 text-secondary-black md:text-16">
                    {formatPaymentMessage({
                        id: 'payment.details',
                        defaultMessage: 'Delete or add payment methods.'
                    })}
                </Typography>
            </div>

            {account?.token?.paymentToken && (
                <div className="mt-32 w-full lg:w-[90%]">
                    <PaymentCard key={0} />
                </div>
            )}
            <Button
                variant="primary"
                onClick={() => router.push('?hash=payment&id=add')}
                title={
                    account?.token?.paymentToken
                        ? formatPaymentMessage({
                              id: 'button.disabled',
                              defaultMessage: 'Please delete the current card to be able to add new'
                          })
                        : ''
                }
                disabled={!!account?.token?.paymentToken}
                className="mt-24 min-w-full md:min-w-150 lg:mt-36 lg:min-w-200"
                data-cy="account-payment-add-card-button"
            >
                {formatPaymentMessage({
                    id: 'add.card',
                    defaultMessage: 'Add new card'
                })}
            </Button>
        </div>
    );
};

export default PaymentMethods;
