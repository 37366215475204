import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import type { Account } from '@wilm/shared-types/account';
import toast from 'react-hot-toast';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import useResolveCCImage from 'components/commercetools-ui/organisms/checkout/hooks/useResolveCCImage';
import { useFormat } from 'helpers/hooks/useFormat';
import { sdk } from 'sdk';
import { useAccount } from 'frontastic';
import PaymentDelete from './payment-delete';

const PaymentCard: FC = () => {
    const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
    const resolveCCImage = useResolveCCImage();
    const { account, mutateAccount } = useAccount();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [cardFlagUrl, setCardFlagUrl] = useState('');

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const deleteCard = async () => {
        const response = await sdk.callAction({
            actionName: `cart/deleteToken`
        });
        const accountResponse = (response.isError ? null : response.data) as Account;
        if (!accountResponse) {
            toast.error(
                `${formatPaymentMessage({
                    id: 'customer.token.deletion.failed',
                    defaultMessage: 'Token deletion failed'
                })}`
            );
        }
        await mutateAccount();

        setModalIsOpen(false);
    };

    useEffect(() => {
        if (account?.token?.cardNumber) {
            const resolvedUrl = resolveCCImage(account?.token?.cardNumber);
            if (resolvedUrl) {
                setCardFlagUrl(resolvedUrl);
            }
        }
    }, [account?.token?.cardNumber, resolveCCImage]);

    return (
        <div className="mt-16 flex items-center justify-between rounded-md border px-16 py-12 lg:p-24">
            <div className="flex items-center">
                {/* eslint-disable-next-line */}
                {cardFlagUrl && <img alt="payment-cart" className="h-fit w-[32px]" src={cardFlagUrl} />}
                <Typography className="ml-16 text-14 text-primary-black">
                    {account?.token?.cardNumber && account?.token?.cardExpiryMonth && account?.token?.cardExpiryYear
                        ? `...${account?.token?.cardNumber?.substring(
                              account.token.cardNumber.length - 4,
                              account.token.cardNumber.length
                          )} ${account?.token?.cardExpiryMonth}/${account?.token?.cardExpiryYear}  ${account?.token?.currencyCode}`
                        : formatPaymentMessage({
                              id: 'checkout.my.saved.card',
                              defaultMessage: 'My saved card'
                          }) +
                          ' ' +
                          account?.token?.currencyCode}
                </Typography>
            </div>

            <PaymentDelete
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                handleCancelClick={closeModal}
                handleDeleteClick={deleteCard}
            />

            <div className="flex-col justify-start lg:flex lg:max-w-[436px] lg:flex-row lg:justify-between">
                <Button
                    variant="ghost"
                    size="fit"
                    className="flex items-center px-0"
                    onClick={() => setModalIsOpen(true)}
                    data-cy="saved-cc-delete"
                >
                    <TrashIcon className="w-20 text-secondary-black" />
                    <Typography as="h2" className="ml-8 text-center text-14 font-normal text-secondary-black">
                        {formatPaymentMessage({
                            id: 'delete',
                            defaultMessage: 'Delete'
                        })}
                    </Typography>
                </Button>
            </div>
        </div>
    );
};

export default PaymentCard;
