import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import AccountFieldsForm, { AccountFieldsFormType } from '../../../account-atoms/account-fields-form';

const PersonalInfoForm = () => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const formClassName = useClassNames(['mx-16 mt-16 border border-neutral-400 md:mx-40 md:mt-20']);
    const containerClassName = useClassNames(['grid max-w-[460px] gap-12 p-16 md:p-30']);
    const title = formatAccountMessage({ id: 'personal.info.edit', defaultMessage: 'Edit personal information' });

    const { account } = useAccount();

    return (
        <>
            <Typography className="mx-16 mt-30 text-18 md:mx-40 md:mt-48" as="h1">
                {title}
            </Typography>
            <AccountFieldsForm
                type={AccountFieldsFormType.UPDATE}
                account={account}
                formClassName={formClassName}
                containerClassName={containerClassName}
            />
        </>
    );
};

export default PersonalInfoForm;
