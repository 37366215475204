import type { FC } from 'react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
    orderNumber?: string;
    asSkeleton?: boolean;
}

const OrderNumber: FC<Props> = ({ orderNumber, asSkeleton }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    if (asSkeleton) {
        return (
            <>
                <div className="mt-20 max-w-[60%] px-16 md:mt-0 md:pl-24 lg:pl-30">
                    <Skeleton height={22} />
                </div>
                <div className="mt-8 max-w-[60%] px-16 md:mt-0 md:pl-24 lg:pl-30">
                    <Skeleton />
                </div>
            </>
        );
    } else if (orderNumber) {
        return (
            <>
                <div className="mt-16 pb-12 md:mt-0">
                    <Typography
                        as="h2"
                        className="mt-20 px-16 text-primary-black md:mt-0 md:pl-24 md:text-22 lg:pl-30 lg:text-24"
                        data-cy="order-details"
                    >
                        {formatOrdersMessage({
                            id: 'order.details',
                            defaultMessage: 'Order Details'
                        })}
                    </Typography>
                </div>

                <div className="mt-8 flex flex-row pl-16 md:mt-12 md:px-0 md:pl-24 lg:mt-24 lg:pl-30">
                    <Typography className="whitespace-nowrap text-14 text-secondary-black md:text-16">
                        {formatOrdersMessage({
                            id: 'order.number',
                            defaultMessage: 'Order number:'
                        })}
                    </Typography>
                    <Typography className="ml-8 whitespace-nowrap text-14 font-medium text-primary-black md:text-16">
                        {orderNumber?.replaceAll('-', ' ')}
                    </Typography>
                </div>
            </>
        );
    }
};

export default OrderNumber;
