import type { ComponentProps, FC } from 'react';
import { useEffect } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import type { SaveOrCancelProps } from 'components/commercetools-ui/organisms/account/account-atoms/save-or-cancel';
import SaveOrCancel from 'components/commercetools-ui/organisms/account/account-atoms/save-or-cancel';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useDiscardForm from '../hooks/useDiscardForm';

export interface AccountFormProps extends ComponentProps<'form'> {
    title: string;
    subtitle?: string;
    containerClassName?: string;
    requiredLabelIsVisible?: boolean;
    defaultCTASection?: boolean;
    ctaVariant?: SaveOrCancelProps['variant'];
    loading?: boolean;
    onSubmit: (e: React.FormEvent) => void;
}

const AccountForm: FC<AccountFormProps> = ({
    className,
    containerClassName,
    title,
    subtitle,
    loading,
    requiredLabelIsVisible,
    ctaVariant,
    defaultCTASection,
    onSubmit,
    children
}) => {
    const { discardForm } = useDiscardForm();

    const { formatMessage } = useFormat({ name: 'common' });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(e);
    };

    const formClassName = useClassNames(['px-16 pb-40 pt-24 md:px-24 lg:px-40', className]);

    const containerClassNames = useClassNames(['max-w-[416px]', containerClassName]);

    return (
        <form className={formClassName} onSubmit={handleSubmit} noValidate={true}>
            <Typography as="h2" className="text-18 text-primary-black md:mb-12 md:text-22">
                {title}
            </Typography>

            <div className="border-checkout-border relative pb-32 pt-24 md:border md:px-32 md:pt-8">
                {subtitle && (
                    <Typography className="mb-28 text-14 leading-loose text-primary-black md:mb-40 md:text-16">{subtitle}</Typography>
                )}

                <div className={containerClassNames}>
                    {children}

                    {defaultCTASection && (
                        <div className="mt-24 grid items-center justify-between gap-32 md:flex md:gap-16">
                            {requiredLabelIsVisible && (
                                <Typography className="text-14 leading-[114%] text-secondary-black">
                                    {formatMessage({ id: 'required.field', defaultMessage: '* required field' })}
                                </Typography>
                            )}

                            <SaveOrCancel onCancel={discardForm} onSave={handleSubmit} loading={loading} variant={ctaVariant} />
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};

export default AccountForm;
